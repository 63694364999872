<template>
  <span>
    <ButtonComponent
      v-if="!disableLink"
      ref="modalLink"
      :button-type="linkType"
      :clickhandler="open"
      :data-cy="dataCyOpenLink"
    >
      <slot name="link">
        <div>
          <Icon
            v-if="linkIcon"
            name="keyboard__arrow--right"
            height="22"
            up="2"
          />
          <span class="link-text">{{ linkText }}</span>
        </div>
      </slot>
    </ButtonComponent>
    <Teleport to="#modals">
      <dialog
        ref="modal"
        @keydown.esc="escClose"
        class="modal-content"
        :class="{ fullscreen: isMobileFullscreen }"
        :data-cy="dataCy"
        :style="{
          height: getHeight,
          width: width + 'px',
          padding: noPadding ? '0' : '20px 15px',
          borderWidth: noPadding ? '0' : '1px',
          borderColor: borderColor
        }"
      >
        <ButtonComponent
          v-if="closeButton"
          ref="closeButton"
          button-type="look-like-link"
          class="button--close"
          data-cy="modal__close-button"
          :clickhandler="closeClick"
        >
          <span class="visually-hidden">Schließen</span>
          <Icon :name="'close-simple'" height="32" />
        </ButtonComponent>
        <slot name="image"></slot>
        <h1
          v-if="title"
          :id="titleId"
          class="subtitle modal__header"
          :class="{ 'visually-hidden': hideTitleVisually }"
          data-cy="modal__header"
        >
          {{ title }}
        </h1>

        <div
          ref="innerContent"
          :aria-labelledby="title ? titleId : undefined"
          data-cy="modal__content"
          class="modal__inner-content"
          :style="{
            height: height ? '100%' : 'auto',
            overflowY: disableOverflow ? 'unset' : 'scroll'
          }"
        >
          <div class="modal__inner-content_wrapper">
            <slot />
            <Transition name="slide-fade">
              <div
                v-if="showOverflowingHint"
                class="scroll-hint__container"
                @click="onScrollHintClick"
              >
                <span class="scroll-hint">
                  <InlineSvg class="scroll-hint__icon" :src="ScrollHint" />
                </span>
              </div>
            </Transition>
          </div>
        </div>
        <slot name="button">
          <div
            v-if="cta"
            class="modal__buttons"
            :class="{
              'modal__buttons--centered': showCenteredCta
            }"
          >
            <ButtonComponent
              v-if="cancel"
              button-type="secondary"
              class="modal__button__cancel"
              data-cy="modal__button__cancel"
              :clickhandler="
                () => {
                  cancelFunction() && close()
                }
              "
              >{{ cancelText }}</ButtonComponent
            >
            <div v-if="!showCenteredCta" class="modal__spacer" />
            <ButtonComponent
              class="modal__button__cta"
              data-cy="modal__button__cta"
              :clickhandler="
                () => {
                  cta() && (ctaAutoClose ? close() : void 0)
                }
              "
              >{{ ctaText }}</ButtonComponent
            >
          </div>
        </slot>
      </dialog>
    </Teleport>
  </span>
</template>

<script>
import { disableScrollY, enableScrollY, uniqueId } from 'src/lib/helpers'
import { mapGetters, mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from '../icon/icon.vue'
import InlineSvg from '../inline_svg/inline_svg.vue'
import ScrollHint from './resources/scroll-hint.svg'
export default {
  name: 'ModalComponent',
  components: {
    ButtonComponent,
    Icon,
    InlineSvg
  },
  props: {
    id: {
      type: String,
      default: () => `unique-${uniqueId()}`
    },
    onOpen: {
      type: Function,
      default: null
    },
    onClose: {
      type: Function,
      default: null
    },
    onCloseClick: {
      type: Function,
      default: () => {}
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: Boolean,
      default: true
    },
    cancelFunction: {
      type: Function,
      default: () => true
    },
    cancelText: {
      type: String,
      default: 'Abbrechen'
    },
    cta: {
      type: Function,
      default: undefined
    },
    ctaAutoClose: {
      type: Boolean,
      default: true
    },
    ctaText: {
      type: String,
      default: 'Speichern'
    },
    centeredCta: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    backgroundClose: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: 'Modal'
    },
    linkType: {
      type: String,
      default: 'look-like-link'
    },
    linkIcon: {
      type: Boolean,
      default: true
    },
    disableLink: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: String,
      default: undefined
    },
    dataCy: {
      type: String,
      default: undefined
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    hideTitleVisually: {
      type: Boolean,
      default: false
    },
    mobileFullscreen: {
      type: Boolean,
      default: true
    },
    borderColor: {
      type: String,
      default: undefined
    },
    disableOverflow: {
      type: Boolean,
      default: false
    },
    preventEscClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      htmlScrollElement: document.getElementsByTagName('html')[0],
      titleId: `unique-${uniqueId()}`,
      refOrId: undefined,
      showOverflowingHint: false,
      ScrollHint
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    ...mapGetters({
      openModalId: 'modal/getOpenModal'
    }),
    dataCyOpenLink() {
      return this.dataCy ? `${this.dataCy}__open-link` : 'modal__open-link'
    },
    showCenteredCta() {
      return this.centeredCta && !this.cancel
    },
    isNoCloseDialog() {
      return !this.backgroundClose && !this.closeButton
    },
    getHeight() {
      if (this.isMobileFullscreen && !this.height) {
        return undefined
      }

      if (!this.height) {
        if (this.$breakpoint.upToTablet()) {
          return 'unset'
        }

        return undefined
      }

      if (Number.isInteger(this.height)) {
        return this.height + 'px'
      }

      return this.height
    },
    getWidth() {
      if (!this.height) {
        return ''
      }

      if (Number.isInteger(this.width)) {
        return this.width + 'px'
      }

      return this.height
    },
    isMobileFullscreen() {
      return this.mobileFullscreen && this.$breakpoint.upToTablet()
    }
  },
  watch: {
    openModalId(newValue) {
      if (newValue === this.id) {
        this.openInternal()
      }
    }
  },
  mounted() {
    if (this.openModalId === this.id) {
      this.openInternal()
    }

    this.$refs.modal.addEventListener('close', () => {
      this.$store.commit('modal/removeModal', this.id)
      enableScrollY()
      if (this.onClose) {
        this.onClose()
      }
    })

    if (this.backgroundClose) {
      this.$refs.modal.addEventListener('click', e => {
        const dialogDimensions = this.$refs.modal.getBoundingClientRect()

        if (
          e.clientX < dialogDimensions.left ||
          e.clientX > dialogDimensions.right ||
          e.clientY < dialogDimensions.top ||
          e.clientY > dialogDimensions.bottom
        ) {
          this.close()
        }
      })
    }

    if (this.isOpen) {
      this.$refs.modal.showModal()
    }
  },
  beforeUnmount() {
    enableScrollY()

    this.$store.commit('modal/removeModal', this.id)
  },
  methods: {
    open() {
      this.$store.commit('modal/pushModal', this.id)
    },
    async openInternal(refOrId) {
      this.refOrId = refOrId
      this.$refs.modal.showModal()
      if (this.onOpen && typeof this.onOpen === 'function') {
        await this.onOpen()
      }

      const el = this.$refs.innerContent

      if (el) {
        this.showOverflowingHint = el.scrollHeight > el.offsetHeight
        el.addEventListener('scroll', () => {
          this.showOverflowingHint =
            el.scrollTop <= el.scrollHeight - el.offsetHeight - 20
        })
      }

      this.$nextTick(() => disableScrollY())
    },
    close() {
      this.$refs.modal.close()
      this.$store.commit('modal/removeModal', this.id)
      enableScrollY()
    },
    closeClick() {
      this.onCloseClick()
      this.close()
    },
    onScrollHintClick() {
      this.$refs.innerContent.scrollBy(
        0,
        this.$refs.innerContent.offsetHeight * 0.8
      )
      this.showOverflowingHint = false
    },
    escClose(event) {
      if (this.preventEscClose) {
        event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::backdrop {
  background-color: var(--modal--backdrop-background);
}

.button--close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 1rem;
  cursor: pointer;
  background-color: var(--background-primary);

  .icon {
    color: var(--root-color);
  }
}

button.button--close {
  &:hover,
  &:focus {
    border-radius: 50%;
    transition: transform 0.2s ease;
    transform: rotate(90deg);
  }

  .icon {
    &:hover,
    &:focus {
      background-color: var(--background-secondary);
      border-radius: 50%;
    }
  }
}

.modal-content {
  position: fixed;
  color: var(--root-color);
  background-color: var(--background-primary);
  max-width: 95%;
  margin: 5% auto;
  overflow: hidden;
  border-radius: 5px;
  border-style: solid;
  border-color: #ddd;
  outline: 0;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);

  @include breakpoint($from-tablet) {
    margin: auto;
  }
}

@include breakpoint($up-to-tablet) {
  .modal-content {
    max-width: 100vw;
    max-height: 90%;
    margin: 0;
    height: auto;
    border-radius: inherit;
    bottom: 0;
    top: unset;
  }

  .button--close {
    top: 5px;
    right: 5px;
  }
}

.modal__header {
  margin-top: 5px;
  margin-bottom: 15px;
  padding-left: 5px;
  font-size: 1.5rem;
  width: 95%;
}

.modal__inner-content {
  margin-top: 25px;
  max-width: 100%;
  max-height: calc(90vh - 125px);
  padding: 5px;
  scroll-behavior: smooth;
}

.modal__inner-content_wrapper {
  max-height: calc(85vh - 370px);
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
  .modal__inner-content::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 5px;
  }

  .modal__inner-content::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
    border-radius: 0 0 5px;
  }

  .modal__inner-content::-webkit-scrollbar {
    max-width: 7px;
    max-height: 10px;
  }
}

.modal__buttons {
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
  padding-top: 15px;
  padding-bottom: 20px;

  @include breakpoint(500px) {
    gap: 0;
    flex-direction: row;
  }

  @include breakpoint($from-desktop) {
    padding-bottom: 0;
  }

  &.modal__buttons--centered {
    justify-content: center;
    padding-top: 0;
  }
}

.link-text {
  @include font-normal;
}

.modal__spacer {
  flex-grow: 1;
  height: 6px;
  width: 20px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal-open-enter-from,
.modal-open-leave-to {
  opacity: 0;
  transform: scale(0.1);
}

.scroll-hint__container {
  position: absolute;
  bottom: 0;
  height: 50px;
  background: var(--background-primary);
  background: linear-gradient(
    0deg,
    rgb(var(--background-primary-rgb) / 100%) 0%,
    rgb(var(--background-primary-rgb) / 76.1%) 70%,
    rgb(var(--background-primary-rgb) / 0%) 100%
  );
  width: calc(100% - 30px);
  display: flex;
}

.scroll-hint {
  margin: auto auto 0;
}

.scroll-hint__icon {
  height: 20px;
  width: 80px;
  color: var(--root-color);
}

.slide-fade-enter-active {
  transition: all 0.8s 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(25px);
  opacity: 0;
}
</style>
